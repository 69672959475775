<template>
  <div> 
    <b-sidebar
      id="add-new-note"
      v-model="toggle"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
    >
      <AddEditNodePage
        :id="id" 
        :data="itemSelected"
        @refetchData="onFetchData()"
        @close="onClose()"
      />
    </b-sidebar>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2"> 
        <b-row>    
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>{{ $t('common.general.take_note') }}</h3>  
          </b-col>
          <b-col
            cols="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="onAdd()"
              >
                <span class="text-nowrap">{{
                  $t("common.form.add")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPageListTable"
        class="position-relative"
        :items="dataList"
        :fields="tableColumns"
        :busy="isLoadingTable"
        responsive
        primary-key="id"
        show-empty
        :empty-text="$t('common.general.empty_text')"
      >
        <template #cell(image)="data">
          <b-avatar :src="data.item.image" />
        </template> 
        <template #cell(statusCode)="data">
          <b-badge variant="primary">
            {{ data.item.statusCode }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          <b-badge variant="primary">
            {{ formatDatetime(data.item.createdAt) }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-row>
            <b-col
              cols="12"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              size="sm"
              variant="primary"
              @click="onEdit(data.item)"
            >{{ $t("common.form.edit") }}</b-button></b-col>
            <b-col
              cols="12"
              class="mt-1"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-prevent-closing
              block
              size="sm"
              variant="danger"
              @click="onSelectedDelete(data.item)"
            >{{ $t("common.form.delete") }}</b-button></b-col>
            
          </b-row>
        </template>
        <template #table-busy>
          <TableLoading />
        </template>
      </b-table>
    </b-card>  
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      :title="$t('common.form.title_confirm_delete')"
      :ok-title="$t('common.form.delete')"
      :cancel-title="$t('common.form.cancel')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_delete") }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import customerNoteServices from '@/services/customerNoteServices'
import {
  BAvatar,
  BCard, BTable, BButton, BBadge, BCol, BRow,
  VBModal, BModal, BCardText, BSidebar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive' 
import commonServices from '@/services/commonServices';
import TableLoading from '@/components/TableLoading.vue';
import AddEditNodePage from '@/views/pages/customer/AddEditNotePage.vue';
export default {
  components: {
    AddEditNodePage,
    BAvatar,
    BCard, 
    BTable,
    BButton,
    BBadge,
    BCol,
    BRow,
    BModal, 
    BCardText,
    TableLoading,
    BSidebar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    id: {
      type: String,
      required: true,
    }, 
  },
  data(){
    return {
      toggle: false,
      itemSelected: {},
      itemSelectedDelete: {},
      dataList: [],
      perPage: 10,
      perPageOptions: [10],
      isLoadingTable: false,
      tableColumns: [ 
        { key: 'note', label: this.$t('common.general.note'), sortable: true }, 
        { key: 'createdAt', label: this.$t('common.general.createdAt'), sortable: true },
        { key: 'actions', label: this.$t('common.form.actions') },
      ],
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    async getList() {
      this.isLoadingTable = true;
      let items = [];
      // eslint-disable-next-line prefer-const
      let variables = {
        filter: {
          customerID: {
            eq: this.id,
          },
        }, 
      }
      let graphqlData = await customerNoteServices.getList(variables);
      let result = graphqlData.data.listCustomerNotes;
      items = items.concat(result.items);
      while (result.nextToken) {
        // eslint-disable-next-line dot-notation
        variables['nextToken'] = result.nextToken
        // eslint-disable-next-line no-await-in-loop
        graphqlData = await customerNoteServices.getList(
          variables,
        );
        result = graphqlData.data.listCustomerNotes;
        items = items.concat(result.items);
      }
      this.dataList = items;
      this.isLoadingTable = false;
    }, 
    formatDatetime(val){
      return commonServices.formatDatetime(val);
    },
    onFetchData(){
      this.getList();
    }, 
    onSelectedDelete(item){
      this.itemSelectedDelete = item;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault() 
      this.handleSubmit()
    },
    async handleSubmit() {
      await customerNoteServices
        .delete(this.itemSelectedDelete.id)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            }
            this.onFetchData();
            commonServices.showSuccess(this, 'msg.success.success');
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onEdit(item){
      this.itemSelected = item;
      this.toggle = true;
    },
    onAdd(){
      this.itemSelected = {}
      this.toggle = true;
    },
    onClose(){
      this.itemSelected = {}
      this.toggle = false;
    },
  },
}
</script> 
