<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    :variant="`transparent`"
    :opacity="0.5"
    :blur="`10px`"
    rounded="sm"
    spinner-type="grow"
  >
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        {{ inputData.id ? $t("common.form.edit") : $t("common.form.add") }}
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="onClose()"
      />
    </div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <div class="row">
              <div class="col-12">
                <validation-provider
                  #default="validationContext"
                  name="note"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('common.general.note')"
                    label-for="note"
                  >
                    <b-form-textarea
                      id="note"
                      v-model="inputData.note"
                      :state="getValidationState(validationContext)"
                      :placeholder="$t('common.form.please_insert_data')"
                      rows="10"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="col-12">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                >
                  {{ $t("common.form.save") }}
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { 
  BButton,
  BForm,
  BFormGroup,
  BFormTextarea,
  VBToggle,
  BFormInvalidFeedback,
  BOverlay,
  BCard,
  BCardBody,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import customerNoteServices from '@/services/customerNoteServices';
import commonServices from '@/services/commonServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: {
    BCardBody,
    BCard, 
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    id: {
      type: String,
      required: true,
    }, 
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      inputData: {},
      required,
    };
  },
  watch: {
    data(value) {
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  created() {},
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.inputData.customerID = this.id;
      await customerNoteServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success'); 
              this.$emit('refetchData');
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
          this.onClose();
        });
    },
    async getDetails() {
      this.isLoading = true;
      await customerNoteServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getCustomerNote;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onClose(){
      this.$emit('close');
    },
  },
};
</script>
