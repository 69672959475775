/* eslint-disable no-param-reassign */
import { API } from 'aws-amplify';
import * as queries from '@/graphql/queries';
export default {
  async getDetail(id) {
    return API.graphql({
      query: queries.getCustomerStripe,
      variables: { id },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async getList(variables) {
    return API.graphql({
      query: queries.listCustomerStripes,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables,
    })
  },  

}
