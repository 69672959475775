<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      :variant="`transparent`"
      :opacity="0.5"
      :blur="`10px`"
      rounded="sm"
      spinner-type="grow"
    >
      <section>
        <b-card no-body>
          <b-card-body>
            <b-img
              v-if="details.verified"
              :src="require('@/assets/images/illustration/badge.svg')"
              class="congratulation-medal"
              alt="Medal Pic"
            />
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.fullname')"
                  label-for="fullname"
                >
                  <b-form-input
                    id="fullname"
                    v-model="details.fullname"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.email')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="details.email"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.phone_number')"
                  label-for="email"
                >
                  <b-form-input
                    id="address"
                    v-model="details.phone_number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row> 
              <b-col>
                <b-form-group
                  :label="$t('common.general.number_street')"
                  label-for="number_street"
                >
                  <b-form-input
                    id="number_street"
                    v-model="details.numberStreet"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.additional_address')"
                  label-for="additional_address"
                >
                  <b-form-input
                    id="additional_address"
                    v-model="details.additionalAddress"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.zip_code')"
                  label-for="zip_code"
                >
                  <b-form-input
                    id="zip_code"
                    v-model="details.zipCode"
                  />
                </b-form-group>
              </b-col>
               <b-col>
                <b-form-group
                  :label="$t('common.general.city')"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="details.city"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.status_code')"
                  label-for="status_code"
                >
                  <b-badge variant="light-primary">
                    {{ details.statusCode }}
                  </b-badge>
                </b-form-group></b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.company_already_created')"
                  label-for="company_already_created"
                >
                  <b-form-checkbox
                    v-model="details.company_already_created"
                    :checked="details.company_already_created"
                    switch
                    inline
                  /></b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.creation_of_current_business')"
                  label-for="creation_of_current_business"
                >
                  <b-form-checkbox
                    :checked="!details.company_already_created"
                    switch
                    inline
                  /></b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.payment')"
                  label-for="payment"
                >
                  <b-form-checkbox
                    v-model="details.payment"
                    :checked="details.payment"
                    switch
                    inline
                  /></b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.createdAt')"
                  label-for="status_code"
                >
                  <b-badge variant="light-primary">
                    {{ formatDatetime(details.createdAt) }}
                  </b-badge>
                </b-form-group></b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.package')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="this.package.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.price')"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="this.package.price"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row> 
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.contract_fromdate')"
                  label-for="contractFromDate"
                >
                  <b-form-input
                    id="contractFromDate"
                    v-model="contractFromDate"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.contract_todate')"
                  label-for="contractToDate"
                >
                  <b-form-input
                    id="contractToDate"
                    v-model="contractToDate"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  v-if="contract && !contract.hasTrial && details.verified && details.statusCode == 'ACTIVE'"
                  v-b-modal.modal-trial-prevent-closing
                  variant="primary"
                  class="mr-2"
                >
                  <span class="text-nowrap">{{
                    $t("common.general.trial")
                  }}</span>
                </b-button>
                <b-button
                  v-if="details.verified && details.statusCode == 'ACTIVE'"
                  v-b-modal.modal-extend-prevent-closing
                  variant="primary"
                  class="mr-2"
                >
                  <span class="text-nowrap">{{
                    $t("common.general.extend")
                  }}</span>
                </b-button>
                <b-button
                  v-if="!details.verified && details.statusCode == 'ACTIVE'"
                  v-b-modal.modal-verify-prevent-closing
                  variant="primary"
                  class="mr-2"
                >
                  <span class="text-nowrap">{{
                    $t("common.general.verify")
                  }}</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="updateCustomer(details)"
                >
                  <span class="text-nowrap">{{
                    $t("common.general.update")
                  }}</span>
                </b-button>
              </b-col> 
            </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body>
            <b-table
              ref="refPageListTable"
              class="position-relative"
              :items="dataList"
              :fields="tableColumns"
              :busy="isLoadingTable"
              responsive
              primary-key="id"
              show-empty
              :empty-text="$t('common.general.empty_text')"
            >
              <template #cell(amount)="data">
                {{ data.item.amount / 100 }}
              </template>
              <template #cell(createdAt)="data">
                <b-badge variant="primary">
                  {{ formatDatetime(data.item.createdAt) }}
                </b-badge>
              </template>
              <template #cell(receipt_url)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  :href="data.item.receipt_url"
                  target="_blank"
                >
                  {{ $t("common.form.details") }}
                </b-button>
              </template>

              <template #table-busy>
                <TableLoading />
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </section>
      <section>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.contract')"
                  label-for="contract"
                >
                  <b-media
                    no-body
                    class="mb-75"
                  >
                    <b-media-aside>
                      <b-overlay
                        :show="isUploadContract"
                        class="d-inline-block"
                      >
                        <a
                          target="_blank"
                          :href="details.contract ? details.contract : '#'"
                        >
                          <b-img
                            rounded
                            :src="details.contract ? filePDF : fileDefault"
                            height="80"
                          />
                        </a>
                        
                      </b-overlay>
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <b-form-group class="mb-0">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputElContract.$el.click()"
                        >
                          {{ $t('common.general.upload') }}
                        </b-button>
                        <b-form-file
                          ref="refInputElContract"
                              
                          accept=".pdf"
                          :hidden="true"
                          plain
                          @input="uploadContract($event, true)"
                        />
                      </b-form-group>
                      <b-card-text>Allowed PDF, JPG or PNG.</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              
              </b-col> 
            </b-row> 
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.identity_front')"
                  label-for="identity_front"
                >
                  <b-media
                    no-body
                    class="mb-75"
                  >
                    <b-media-aside>
                      <b-overlay
                        :show="isUploadFront"
                        class="d-inline-block"
                      >
                        <a
                          target="_blank"
                          :href="details.identityFront ? details.identityFront : '#'"
                        >
                          <b-img
                            rounded
                            :src="
                              details.identityFront ? details.identityFront : imageDefault
                            "
                            height="80"
                          />
                        </a>
                      </b-overlay>
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <b-form-group class="mb-0">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputElFront.$el.click()"
                        >
                          {{ $t('common.general.upload') }}
                        </b-button>
                        <b-form-file
                          ref="refInputElFront"
                              
                          accept=".jpg, .png"
                          :hidden="true"
                          plain
                          @input="upload($event, true)"
                        />
                      </b-form-group>
                      <b-card-text>Allowed JPG or PNG.</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              
              </b-col> 
              <b-col>
                <b-form-group
                  :label="$t('common.general.identity_back')"
                  label-for="identity_back"
                >
                  <b-media
                    no-body
                    class="mb-75"
                  >
                    <b-media-aside>
                      <b-overlay
                        :show="isUploadBack"
                        class="d-inline-block"
                      >
                        <a
                          target="_blank"
                          :href="details.identityBack ? details.identityBack : '#'"
                        >
                          <b-img
                            rounded
                            :src="
                              details.identityBack ? details.identityBack : imageDefault
                            "
                            height="80"
                          />
                        </a>
                      </b-overlay>
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <b-form-group class="mb-0">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputElBack.$el.click()"
                        >
                          {{ $t('common.general.upload') }}
                        </b-button>
                        <b-form-file
                          ref="refInputElBack"
                              
                          accept=".jpg, .png"
                          :hidden="true"
                          plain
                          @input="upload($event, false)"
                        />
                      </b-form-group>
                      <b-card-text>Allowed JPG or PNG.</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              </b-col> 
            </b-row> 
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body>
            <b-table
              ref="refPageListTable"
              class="position-relative"
              :items="dataList"
              :fields="tableColumns"
              :busy="isLoadingTable"
              responsive
              primary-key="id"
              show-empty
              :empty-text="$t('common.general.empty_text')"
            >
              <template #cell(amount)="data">
                {{ data.item.amount / 100 }}
              </template>
              <template #cell(createdAt)="data">
                <b-badge variant="primary">
                  {{ formatDatetime(data.item.createdAt) }}
                </b-badge>
              </template>
              <template #cell(receipt_url)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  :href="data.item.receipt_url"
                  target="_blank"
                >
                  {{ $t("common.form.details") }}
                </b-button>
              </template>

              <template #table-busy>
                <TableLoading />
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </section>
    </b-overlay>
    <ListNodePage :id="id" />
    <ListAppointmentPage :prospect-i-d="prospectID" />
    <b-modal
      id="modal-verify-prevent-closing"
      ref="my-modal-verify"
      :title="$t('common.form.title_confirm_verify')"
      :ok-title="$t('common.form.verify')"
      :cancel-title="$t('common.form.cancel')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_verify") }}
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-trial-prevent-closing"
      ref="my-modal-trial"
      :title="$t('common.form.title_confirm_trial')"
      :ok-title="$t('common.general.trial')"
      :cancel-title="$t('common.form.cancel')"
      cancel-variant="outline-secondary"
      @ok="handleTrialOk"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_trial") }}
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-extend-prevent-closing"
      ref="my-modal-extend"
      :title="$t('common.form.title_confirm_extend')"
      :ok-title="$t('common.general.extend')"
      :cancel-title="$t('common.form.cancel')"
      cancel-variant="outline-secondary"
      @ok="handleExtendOk"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_extend") }}
      </b-card-text>
      <b-form-datepicker
        id="example-datepicker-2"
        v-model="extendToDate"
        today-button
        reset-button 
        close-button 
        class="mb-2"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  VBToggle, 
  VBModal,
  BModal,
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BTable,
  BBadge,
  BButton, 
  BImg,
  BCardText,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormDatepicker,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import packageServices from '@/services/packageServices';
import customerServices from '@/services/customerServices';
import contractServices from '@/services/contractServices';
import customerStripeServices from '@/services/customerStripeServices';
import { imageDefault, fileDefault, filePDF } from '@/services/constant';
import enums from '@/services/enumsServices';
import TableLoading from '@/components/TableLoading.vue';
import ListNodePage from '@/views/pages/customer/ListNotePage.vue';
import commonServices from '@/services/commonServices';
import ListAppointmentPage from '@/views/pages/prospect/ListAppointmentPage.vue';
import moment from 'moment';
import _ from 'lodash';
export default {
  components: {
    BMedia,
    BMediaBody,
    BMediaAside,
    BImg,
    ListNodePage,
    BOverlay,
    BModal,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormCheckbox,
    BTable,
    TableLoading,
    BBadge,
    BButton, 
    BCardText,
    BFormDatepicker, 
    ListAppointmentPage,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  data() {
    return {
      imageDefault,
      fileDefault,
      filePDF,
      isLoading: false,
      id: this.$route.query.id,
      details: {},
      package: {},
      contract: {},
      isLoadingTable: false,
      isUploadFront: false, 
      isUploadBack: false, 
      isUploadContract: false,
      dataList: [],
      tableColumns: [
        { key: 'amount', label: this.$t('common.general.amount') },
        { key: 'name', label: this.$t('common.general.name') }, 
        { key: 'receipt_url', label: this.$t('common.general.receipt_url') },
        { key: 'createdAt', label: this.$t('common.general.createdAt') },
      ],
      prospectID: '',
      extendToDate: null,
      defaultExtendToDate: null,
    };
  },
  computed: {
    contractFromDate(){
      if (this.contract && this.contract.fromDate) return commonServices.getLocalDateTime(this.contract.fromDate);
      return null;
    },
    contractToDate(){
      if (this.contract && this.contract.toDate) return commonServices.getLocalDateTime(this.contract.toDate);
      return null;
    },
  },
  created() {
    if (this.id) {
      this.getDetails();
    }
  },
  methods: {
    async getContract(customerID) {
      if (!this.id) return null;
      let items = [];
      // eslint-disable-next-line prefer-const
      let variables = { filter: { customerID: { eq: customerID } } }
      let graphqlData = await contractServices.getList(variables);
      let result = graphqlData.data.listContracts;
      items = items.concat(result.items);
      while (result.nextToken) {
        // eslint-disable-next-line dot-notation
        variables['nextToken'] = result.nextToken
        // eslint-disable-next-line no-await-in-loop
        graphqlData = await contractServices.getList(
          variables,
        );
        result = graphqlData.data.listContracts;
        items = items.concat(result.items);
      }
      return items && items.length > 0 ? items[0] : null;
    },
    async getDetails() {
      if (!this.id) return;
      this.isLoading = true;
      await customerServices
        .getDetail(this.id)
        .then(async (result) => {
          if (result) {
            this.details = result.data.getCustomer;
            this.prospectID = this.details.prospectID;
            this.getListPayment(this.id);
            if (this.details.packageID) {
              this.getPackageDetails(this.details.packageID);
            }
            this.contract = await this.getContract(this.id);
            this.intDataForForm();
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },  
    async getPackageDetails(id) {
      await packageServices
        .getDetail(id)
        .then((result) => {
          if (result) {
            this.package = result.data.getPackage;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getListPayment(id) {
      this.isLoading = true;
      let items = [];
      // eslint-disable-next-line prefer-const
      let variables = {
        filter: {
          customerID: {
            eq: id,
          },
        },
      }
      let graphqlData = await customerStripeServices.getList(variables);
      let result = graphqlData.data.listCustomerStripes;
      items = items.concat(result.items);
      while (result.nextToken) {
        // eslint-disable-next-line dot-notation
        variables['nextToken'] = result.nextToken
        // eslint-disable-next-line no-await-in-loop
        graphqlData = await customerStripeServices.getList(
          variables,
        );
        result = graphqlData.data.listCustomerStripes;
        items = items.concat(result.items);
      }
      this.dataList = items;
      this.isLoading = false;
    },
    formatDatetime(val) {
      return commonServices.formatDatetime(val);
    },
    handleTrialOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.trialOneMonth()
    },
    handleExtendOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.extendToDate === this.defaultExtendToDate){
        this.extendOneMonth()
      } else {
        const localTime = moment(`${this.extendToDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');
        const utcTime = commonServices.getUTCDateTimeStr(localTime);
        this.extendContractToDate(utcTime);
      }
    },
    resetForm(){
      const current = moment();
      this.extendToDate = current.add(1, 'months').format('YYYY-MM-DD');
      this.defaultExtendToDate = _.cloneDeep(this.extendToDate);
    },
    intDataForForm(){
      if (!this.contract){
        this.resetForm() 
      } else {
        this.extendToDate = moment(this.contract.toDate).add(1, 'months').format('YYYY-MM-DD');
        this.defaultExtendToDate = _.cloneDeep(this.extendToDate)
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.verifyCustomer()
    },
    verifyCustomer() {
      this.isLoading = true;
      const input = {
        id: this.details.id,
        verified: true,
      }
      this.$nextTick(() => {
        this.$refs['my-modal-verify'].toggle('#toggle-btn')
      })
      this.updateCustomer(input);
    },
    async extendOneMonth(){
      // eslint-disable-next-line no-alert

      if (!this.contract){
        this.$nextTick(() => {
          this.$refs['my-modal-extend'].toggle('#toggle-btn')
        })
        return;
      }
      const extend = await contractServices.extendOneMonth(this.contract.id);
      if (extend.errors){
        commonServices.showError(this, 'msg.error.error');
      } else {
        commonServices.showSuccess(this, 'msg.success.success');
        this.contract = await this.getContract(this.id);
        this.$nextTick(() => {
          this.$refs['my-modal-extend'].toggle('#toggle-btn')
        })
      }
    },
    async extendContractToDate(toDate){
      // eslint-disable-next-line no-alert

      if (!this.contract){
        this.$nextTick(() => {
          this.$refs['my-modal-extend'].toggle('#toggle-btn')
        })
        return;
      }
      const extend = await contractServices.extendToDate(this.contract.id, toDate);
      if (extend.errors){
        commonServices.showError(this, 'msg.error.error');
      } else {
        commonServices.showSuccess(this, 'msg.success.success');
        this.contract = await this.getContract(this.id);
        this.$nextTick(() => {
          this.$refs['my-modal-extend'].toggle('#toggle-btn')
        })
      }
    },
    async trialOneMonth(){
      // eslint-disable-next-line no-alert
      if (!this.contract){
        this.$nextTick(() => {
          this.$refs['my-modal-extend'].toggle('#toggle-btn')
        })
        return;
      }
      const trial = await contractServices.trial(this.contract.id);
      if (trial.errors){
        commonServices.showError(this, 'msg.error.error');
      } else {
        commonServices.showSuccess(this, 'msg.success.success');
        this.contract = await this.getContract(this.id);
        this.$nextTick(() => {
          this.$refs['my-modal-trial'].toggle('#toggle-btn')
        })
      }
    },
    async upload($event, front) {
      if (front){
        this.isUploadFront = true;
      } else {
        this.isUploadBack = true;
      }
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = async () => {
        await commonServices
          .uploadImage($event, `customer/${this.details.id}/identity`, enums.levelS3.public)
          .then((result) => { 
            const input = {
              id: this.details.id,
            }
            if (front){
              input.identityFront = result
            } else {
              input.identityBack = result
            }
            this.updateCustomer(input);
          })
          .finally(() => {
            if (front){
              this.isUploadFront = false;
            } else {
              this.isUploadBack = false;
            }
          });
      };
    },
    async uploadContract($event) {
      this.isUploadContract = true;
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = async () => {
        await commonServices
          .uploadImage($event, `customer/${this.details.id}/contract`, enums.levelS3.public)
          .then((result) => { 
            const input = {
              id: this.details.id,
            }
            input.contract = result
            this.updateCustomer(input);
          })
          .finally(() => {
            this.isUploadContract = false;
          });
      };
    },
    async updateCustomer(input){
      this.isLoading = true;
      await customerServices
        .update(input)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            }
            if (result.data.updateCustomer){
              commonServices.showSuccess(this, 'msg.success.success');
              this.details = result.data.updateCustomer;
            }
          } 
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
