/* eslint-disable no-param-reassign */
import { API } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
export default {
  async getDetail(id) {
    return API.graphql({
      query: queries.getContract,
      variables: { id },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  }, 
  async getList(variables) {
    return API.graphql({
      query: queries.listContracts,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables,
    })
  },  
  async trial(contractID) { 
    return API.graphql({
      query: mutations.extendTrialOneMonthContract,
      variables: {
        contractID,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  async extendOneMonth(contractID) { 
    return API.graphql({
      query: mutations.extendContract,
      variables: {
        contractID,
        toDate: '',
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  async extendToDate(contractID, toDate) { 
    return API.graphql({
      query: mutations.extendContract,
      variables: {
        contractID,
        toDate,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
}
