import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import moment from 'moment';
export default {
  async createAppointment(options) {
    return API.graphql({
      query: mutations.appointmentProspect,
      variables: {
        startDate: options.startDate,
        endDate: options.endDate,
        appointmentSummary: options.appointmentSummary,
        appointmentDescription: options.appointmentDescription,
        prospectID: options.prospectID,
        location: options.location,
        guests: options.guests,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async getAllGoogleServices(){
    return API.graphql({
      query: queries.listGoogleServices,
      variables: {
        limit: 9999,
        filter: {
          statusCode: {
            eq: 'ACTIVE',
          },
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async getListGoogleServiceByProspectID(variables = {}){
    return API.graphql({
      query: queries.listGoogleServiceByProspectID,
      variables,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async deleteAppointment(id, eventId){
    return API.graphql({
      query: mutations.deleteAppointmentProspect,
      variables: { 
        id,
        eventId,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async updateAppointment(options){
    return API.graphql({
      query: mutations.updateAppointmentProspect,
      variables: {
        id: options.id,
        eventId: options.eventId,
        startDate: options.startDate,
        endDate: options.endDate,
        appointmentSummary: options.appointmentSummary,
        appointmentDescription: options.appointmentDescription,
        prospectID: options.prospectID,
        location: options.location,
        guests: options.guests,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  convertCalendarObject(googleServices){
    // eslint-disable-next-line prefer-const
    let events = []
    if (!googleServices) return [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < googleServices.length; i++){
      const googleEvent = googleServices[i];
      const data = JSON.parse(googleEvent.data)
      // eslint-disable-next-line new-cap
      const endDate = new moment(googleEvent.endDate.replace('Z', '')).utc();
      const endDateStr = `${endDate.format('YYYY-MM-DD')}T${endDate.format('HH:mm:ss')}Z`;
      // eslint-disable-next-line new-cap
      const startDate = new moment(googleEvent.startDate.replace('Z', '')).utc();
      const startDateStr = `${startDate.format('YYYY-MM-DD')}T${startDate.format('HH:mm:ss')}Z`;
      if (data && data.htmlLink){
        events.push({
          allDay: false,
          end: endDateStr,
          extendedProps: {
            calendar: 'Business',
            guests: googleEvent.guests ? googleEvent.guests.split(',') : [],
          },
          id: i,
          start: startDateStr,
          title: googleEvent.summary,
          location: googleEvent.location,
          description: googleEvent.description,
          eventUrl: data.htmlLink,
          // url: data.htmlLink,
        });
      }
    }
    return events;
  },
}
