<template>
  <div class="appointments"> 
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2"> 
        <b-row>    
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>{{ $t('pages.appointment.title') }}</h3>  
          </b-col>
          <b-col
            cols="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-b-modal.modal-appointment
                variant="primary"
                @click="appointment()"
              >
                <span class="text-nowrap">{{
                  $t("common.form.add")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPageListTable"
        class="position-relative"
        :items="dataList"
        :fields="tableColumns"
        :busy="isLoadingTable"
        responsive
        primary-key="id"
        show-empty
        :empty-text="$t('common.general.empty_text')"
      >
        <template #cell(image)="data">
          <b-avatar :src="data.item.image" />
        </template> 
        <template #cell(statusCode)="data">
          <b-badge variant="primary">
            {{ data.item.statusCode }}
          </b-badge>
        </template>
        <template #cell(startDate)="data">
          <b-badge variant="primary">
            {{ formatDatetime(data.item.startDate) }}
          </b-badge>
        </template>
        <template #cell(endDate)="data">
          <b-badge variant="primary">
            {{ formatDatetime(data.item.endDate) }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          <b-badge variant="primary">
            {{ formatDatetime(data.item.createdAt) }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-row>
            <b-col
              cols="12"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-appointment
              block
              size="sm"
              variant="primary"
              @click="appointment(data.item)"
            >{{ $t("common.form.edit") }}</b-button></b-col>
            <b-col
              cols="12"
              class="mt-1"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-delete
              block
              size="sm"
              variant="danger"
              @click="onSelectedDelete(data.item)"
            >{{ $t("common.form.delete") }}</b-button></b-col>
            
          </b-row>
        </template>
        <template #table-busy>
          <TableLoading />
        </template>
      </b-table>
    </b-card>  
    <b-modal
      id="modal-delete"
      ref="delete-modal"
      :title="$t('common.form.title_confirm_delete')"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_delete") }}
      </b-card-text>
      <template #modal-footer>
        <b-button
          :disabled="isLoading"
          variant="outline-secondary"
          @click="onCancelDelete"
        >
          {{ $t('common.form.cancel') }}
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="handleOk($event)"
        >
          {{ $t('common.form.delete') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-appointment"
      ref="appointment-modal"
      :title="$t('pages.appointment.title')"
      no-close-on-backdrop
    >
      <form
        ref="form"
        @submit.stop.prevent="handleAppointmentOk"
      >
        <b-form-group
          id="fieldset-1"
          :label="$t('pages.appointment.enter_summary')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="appointmentSummary"
            trim
          />
        </b-form-group>
        <b-form-group
          id="fieldset-2"
          :label="$t('pages.appointment.enter_desc')"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="appointmentDescription"
            trim
          />
        </b-form-group>
        <b-form-group
          id="fieldset-4"
          :label="$t('pages.appointment.enter_location')"
          label-for="input-4"
        >

          <b-form-input
            id="input-4"
            v-model="appointmentLocation"
            trim
          />
          
        </b-form-group>
        <b-form-group
          id="fieldset-5"
          :label="$t('pages.appointment.enter_guest_emails')"
          label-for="input-5"
        >
          <b-form-tags
            v-model="appointmentGuest"
            add-button-text="Add"
            add-button-variant="primary"
            input-id="tags-pills"
            tag-variant="primary"
            :tag-validator="tagValidator"
            tag-pills
            size="md"
            separator=" "
            placeholder=""
          />   
          <template #invalid-feedback>
            {{ $t('pages.appointment.msg_email_invalid') }}
          </template>       
        </b-form-group>
        <b-form-group
          id="fieldset-3"
          :label="$t('pages.appointment.enter_from_date')"
          label-for="input-3"
        >
          <b-form-datepicker
            id="example-datepicker"  
            v-model="appointmentFromDate"
            today-button
            reset-button 
            close-button 
            class="mb-2"
          />
          <b-form-timepicker
            id="timepicker-placeholder"
            v-model="appointmentFromTime"
            now-button
            reset-button
            :placeholder="$t('pages.appointment.msg_choice_time')"
            locale="en"
          />
        </b-form-group>
        <b-form-group
          id="fieldset-6"
          :label="$t('pages.appointment.enter_to_date')"
          label-for="input-6"
        >
          <b-form-datepicker
            id="example-datepicker-2"
            v-model="appointmentToDate"
            today-button
            reset-button 
            close-button 
            class="mb-2"
          />
          <b-form-timepicker
            id="timepicker-placeholder-2"
            v-model="appointmentToTime"
            now-button
            reset-button
            :placeholder="$t('pages.appointment.msg_choice_time')"
            locale="en"
          />
        </b-form-group>
      </form>
      <template #modal-footer>
        <b-button
          :disabled="isLoading"
          variant="outline-secondary"
          @click="cancelAppointment()"
        >
          {{ $t("common.form.cancel") }}
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="handleAppointmentOk($event)"
        >
          {{ itemSelected ? $t("common.form.update") : $t("common.form.add") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style lang="scss" scoped>
  .appointments{
    margin-top: 25px;
  }

  #tags-pills{
    min-height: 25px;
  }
</style>
<script>
import commonServices from '@/services/commonServices';
import googleService from '@/services/googleService';
import moment from 'moment';
import {
  BAvatar,
  BCard, BTable, BButton, BBadge, BCol, BRow,
  VBModal, BModal, BCardText, BFormInput, BFormGroup, BFormDatepicker, BFormTimepicker, BFormTags,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive' 
import TableLoading from '@/components/TableLoading.vue';
import _ from 'lodash';
export default {
  components: {
    BAvatar,
    BCard, 
    BTable,
    BButton,
    BBadge,
    BCol,
    BRow,
    BModal, 
    BCardText,
    TableLoading,
    BFormInput, 
    BFormGroup, 
    BFormDatepicker, 
    BFormTimepicker,
    BFormTags,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: ['prospectID'],
  data(){
    return {
      isLoading: false,
      // isAddEdit: false,
      itemSelected: {},
      itemSelectedDelete: {},
      dataList: [],
      perPage: 10,
      perPageOptions: [10],
      isLoadingTable: false,
      tableColumns: [ 
        { key: 'summary', label: this.$t('pages.appointment.summary'), sortable: true }, 
        { key: 'description', label: this.$t('pages.appointment.description'), sortable: true },
        { key: 'startDate', label: this.$t('pages.appointment.startDate'), sortable: true }, 
        { key: 'endDate', label: this.$t('pages.appointment.endDate'), sortable: true }, 
        { key: 'createdAt', label: this.$t('common.general.createdAt'), sortable: true },
        { key: 'actions', label: this.$t('common.form.actions') },
      ],
      guestText: null,
      appointmentGuest: [],
      appointmentSummary: '',
      appointmentDescription: '',
      appointmentLocation: '',
      appointmentFromDate: null,
      appointmentFromTime: null,
      appointmentToDate: null,
      appointmentToTime: null,
      appointmentProspectID: null,
    }
  },
  watch: {
    prospectID(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.getList();
    },
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    async getList() {
      this.isLoadingTable = true;
      let items = [];
      // eslint-disable-next-line prefer-const
      const variables = {
        prospectID: this.prospectID,
        statusCode: {
          eq: 'ACTIVE',
        },
      }
      let graphqlData = await googleService.getListGoogleServiceByProspectID(variables);
      let result = graphqlData.data.listGoogleServiceByProspectID;
      items = items.concat(result.items);
      while (result.nextToken) {
        // eslint-disable-next-line dot-notation
        variables['nextToken'] = result.nextToken
        // eslint-disable-next-line no-await-in-loop
        graphqlData = await googleService.getListGoogleServiceByProspectID(
          variables,
        );
        result = graphqlData.data.listGoogleServiceByProspectID;
        items = items.concat(result.items);
      }
      this.dataList = items;
      this.isLoadingTable = false;
    }, 
    formatDatetime(val){
      return commonServices.formatUTCDatetime(val);
    },
    async onFetchData(){
      await this.getList();
    }, 
    onSelectedDelete(item){
      this.itemSelectedDelete = item;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault() 
      this.handleSubmit()
    },
    async handleSubmit() {
      try {
        this.isLoading = true;
        const result = await googleService.deleteAppointment(this.itemSelectedDelete.id, this.itemSelectedDelete.eventId);
        if (result){
          if (result.errors) {
            commonServices.showError(this, 'msg.error.error');
          } else {
            await this.onFetchData();
            commonServices.showSuccess(this, 'msg.success.success');
            this.$bvModal.hide('modal-delete');
          }
        }
      } catch (error){
        commonServices.showError(this, 'msg.error.error');
      } finally {
        this.isLoading = false;
      };
    },
    onEdit(item){
      this.itemSelected = item;
    },
    onCancelDelete(){
      this.itemSelectedDelete = null;
      this.$bvModal.hide('modal-delete');
    },
    cancelAppointment(){
      this.$bvModal.hide('modal-appointment');
    },
    async handleAppointmentOk(bvModalEvt){
      bvModalEvt.preventDefault();
      // console.log(this.appointmentSummary)
      // console.log(this.appointmentDescription)
      // console.log(this.appointmentFromDate)
      // console.log(this.appointmentFromTime)
      // console.log(this.appointmentToDate)
      // console.log(this.appointmentToTime)
      // console.log(this.appointmentProspectID);

      if (!this.appointmentFromDate || !this.appointmentToDate || !this.appointmentToTime || !this.appointmentFromTime){
        commonServices.showError(this, 'Please choice the time');
        bvModalEvt.preventDefault();
        return;
      }
      const startDateStr = `${this.appointmentFromDate}T${this.appointmentFromTime}`;
      const endDateStr = `${this.appointmentToDate}T${this.appointmentToTime}`;
      if (startDateStr === endDateStr){
        commonServices.showError(this, 'The end date must be greater than start date');
        bvModalEvt.preventDefault();
        return;
      }
      // eslint-disable-next-line prefer-const
      let options = {
        startDate: startDateStr,
        endDate: endDateStr,
        appointmentSummary: this.appointmentSummary,
        appointmentDescription: this.appointmentDescription,
        prospectID: this.appointmentProspectID,
        location: this.appointmentLocation,
        guests: this.appointmentGuest ? this.appointmentGuest.join(',') : '',
      };
      this.isLoading = true;
      try {
        let appointmentResult = null;
        if (!this.itemSelected){
          appointmentResult = await googleService.createAppointment(options);
        } else {
          // eslint-disable-next-line dot-notation
          options['id'] = this.itemSelected.id;
          // eslint-disable-next-line dot-notation
          options['eventId'] = this.itemSelected.eventId;
          appointmentResult = await googleService.updateAppointment(options);
        }  
        if (appointmentResult.errors) {
          commonServices.showError(this, 'msg.error.error');
          // eslint-disable-next-line dot-notation
        } else {
          commonServices.showSuccess(this, 'msg.success.success');
          this.$bvModal.hide('modal-appointment');
          this.onFetchData();
        }
      } catch (error){
        commonServices.showError(this, 'msg.error.error');
      } finally {
        this.isLoading = false;
      };
    },
    resetAppointmentData(){
      const current = moment();
      this.itemSelected = null;
      this.appointmentSummary = '';
      this.appointmentDescription = '';
      this.appointmentFromDate = current.format('YYYY-MM-DD');
      this.appointmentFromTime = current.format('HH:mm:ss');
      this.appointmentToDate = current.format('YYYY-MM-DD');
      this.appointmentToTime = current.format('HH:mm:ss');
      this.appointmentProspectID = null;
      this.guestText = null;
      this.appointmentGuest = [];
    },
    appointment(item = null){
      if (!item){
        this.resetAppointmentData();
      } else {
        this.itemSelected = item;
        this.appointmentEditId = item.id;
        this.appointmentSummary = item.summary;
        this.appointmentDescription = item.description;
        // eslint-disable-next-line new-cap
        this.appointmentFromDate = new moment(item.startDate).utc().format('YYYY-MM-DD');
        // eslint-disable-next-line new-cap
        this.appointmentFromTime = new moment(item.startDate).utc().format('HH:mm:ss');
        // eslint-disable-next-line new-cap
        this.appointmentToDate = new moment(item.endDate).utc().format('YYYY-MM-DD');
        // eslint-disable-next-line new-cap
        this.appointmentToTime = new moment(item.endDate).utc().format('HH:mm:ss');
        this.appointmentLocation = item.location;
        this.appointmentGuest = item.guests ? item.guests.split(',') : [];
      }
      this.appointmentProspectID = this.prospectID;
    },
    tagValidator(tag){
      // eslint-disable-next-line no-useless-escape
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (!tag) return false;
      if (!reg.test(tag)) return false;
      const findExist = _.find(this.appointmentGuest, x => x === tag);
      if (!findExist){
        return true;
      }
      return false;
    },

  },
}

</script>
