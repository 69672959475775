/* eslint-disable import/prefer-default-export */
import i18n from '@/libs/i18n'

export const imageDefault = require('@/assets/images/avatars/no-image-found-360x250.png');
export const fileDefault = require('@/assets/images/avatars/no-file-found.png');
export const filePDF = require('@/assets/images/avatars/file-pdf.png');
export const extraPlugins = ['colorbutton', 'colordialog', 'justify', 'templates', 
  'dialogui', 'dialog', 'a11yhelp', 'about', 'basicstyles', 'bidi', 'blockquote', 'clipboard',
  'button', 'panelbutton', 'panel', 'floatpanel', 'colorbutton', 'menu',
  'contextmenu', 'dialogadvtab', 'div', 'elementspath', 'enterkey', 'entities', 'popup',
  'filebrowser', 'find', 'fakeobjects', 'flash', 'floatingspace', 'listblock', 'richcombo',
  'font', 'format', 'forms', 'horizontalrule', 'htmlwriter', 'iframe', 'image', 'indent',
  'indentblock', 'indentlist', 'justify', 'link', 'list', 'liststyle', 'magicline',
  'maximize', 'pagebreak', 'pastefromword', 'pastetext', 'preview', 'print',
  'removeformat', 'resize', // 'newpage', 'save', 
  'menubutton', 'scayt', 'selectall', 'showblocks',
  'tabletools', 'templates', 'toolbar', 'undo', 'wsc', 'wysiwygarea',
  'uploadimage', 'image2', 'tableresize',
];
export const InputType = {
  SHORT_TEXT: '1',
  IMAGE: '2',
  TEXT: '3',
  SELECT: '4',
  CHECKBOX: '5',
};
console.log(i18n);
export const filterByDuration = [
  
  {
    value: 'yesterday',
    text: i18n.t('common.general.yesterday'),
  },
  {
    value: 'last_week',
    text: i18n.t('common.general.last_week'),
  },
  {
    value: 'last_month',
    text: i18n.t('common.general.last_month'),
  },
  {
    value: 'last_3months',
    text: i18n.t('common.general.last_3months'),
  },
  {
    value: 'last_year',
    text: i18n.t('common.general.last_year'),
  },
 
];

export const articleTemplates = [
  {
    value: '1',
    text: i18n.t('common.general.default'),
  },
  {
    value: '2',
    text: `${i18n.t('common.general.template')} 1`,
  },
]
